import React, { useEffect, useState } from "react";
import logo from "../assets/logo.svg";
import { BurgerButton, Container, ExtendedNav, Links, Logo, Nav } from "./styles/header.styled";

const NavBar = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "scroll";

    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [open]);
    
  return (
    <Container>
      <Logo Img={logo}/>
      <Nav>
        <Links href="#institution">for institutions</Links>
        <Links href="#business">for businesses</Links>
      </Nav>
      <BurgerButton onClick={() => setOpen(!open)}>
        {open ? <>&#215;</> : <>&#8801;</>}
      </BurgerButton>

      {open && <ExtendedNav>
        <Links  onClick={() => setOpen(false)} href="#institution">for institutions</Links>
        <Links  onClick={() => setOpen(false)} href="#business">for businesses</Links>
      </ExtendedNav>}
    </Container>
  );
};

export default NavBar;
